<template>
  <div class="page">
    <Navbar title="订单初审" :callback="back" type="SMP" />
    <van-field name="radio" label="处理结果">
      <template #input>
        <van-radio-group
          v-model="result"
          direction="horizontal"
          @change="resultChange"
          :checked-color="COLOR_M"
        >
          <van-radio name="Y">提交复审</van-radio>
          <van-radio name="N">取消订单</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field
      v-model="comment"
      is-link
      readonly
      name="picker"
      label="处理备注"
      placeholder="点击选择备注"
      @click="commentShow = true"
    />
    <van-popup :show="commentShow" position="bottom">
      <van-picker
        :columns="comments"
        @confirm="commentConfirm"
        @cancel="commentShow = false"
      />
    </van-popup>
    <van-row class="btn">
      <van-col span="8" offset="8"
        ><van-button
          icon="plus"
          block
          :color="COLOR_M"
          @click="applyAudit()"
          size="small"
          >提交初审</van-button
        ></van-col
      >
    </van-row>
  </div>
</template>
<script>
import {
  Field, RadioGroup, Radio, CellGroup, Cell, Tag, Popup, Picker
} from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Popup.name]: Popup,
    [Picker.name]: Picker
  },
  data () {
    return {
      orderCode: '',
      result: 'Y',
      comment: '初审通过',
      comments: ['初审通过'],
      commentShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    this.orderCode = query.orderCode
  },
  methods: {
    resultChange (val) {
      if (val === 'Y') {
        this.comments = ['初审通过']
        this.comment = '初审通过'
      } else {
        this.comments = ['客户要求取消', '无法联系客户', '工作职位无空缺', '工作职位有误']
        this.comment = '客户要求取消'
      }
    },
    commentConfirm (val) {
      this.comment = val
      this.commentShow = false
    },
    applyAudit () {
      this.$dialog.confirm({
        title: '审核确认',
        message: '确认提交审核吗?'
      })
        .then(() => {
          this.applyAuditExecute()
        })
    },
    async applyAuditExecute () {
      var pd = { orderCode: this.orderCode, result: this.result, comment: this.comment }
      const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/order/applyAudit', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      }
    },
    showProcess () {
      this.processShow = true
    }
  }
}
</script>
<style lang="less" scoped>
.btn {
  margin-top: 20px;
}
</style>
